body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #58728d !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

@font-face {
  font-family: "NotoBold";
  src: local("NotoSansJP-Bold"),
    url("./assets/fonts/NotoSansJP-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "NotoMedium";
  src: local("NotoSansJP-Medium"),
    url("./assets/fonts/NotoSansJP-Medium.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "NotoRegular";
  src: local("NotoSansJP-Regular"),
    url("./assets/fonts/NotoSansJP-Regular.ttf") format("truetype");
  font-weight: lighter;
}

.ant-select-item {
  padding: 10px 22px 10px 22px !important;
}

.ant-select-item-option {
  font-family: "NotoRegular" !important;
  font-size: 1.17em !important;
  text-align: center;
  /*margin: 5px 10px 5px 10px;*/
}

.ant-select-item-option-disabled:after {
  font-size: 0.55em;
  transform: rotate(180deg);
  content: "\25bc"
}

.ant-select-item-option.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #e7fadd;
}

.ant-select-item-option:hover {
  background-color: #e7fadd;
}

.anticon-check > svg {
  color: #4cbb17;
}

.grid-pinned-column {
  background-color: #eff1f3;
  text-align: center;
}

.grid-column {
  background-color: #f5f6f7;
}

.grid-column-center {
  background-color: #f5f6f7;
  text-align: center;
}